<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3" v-if="!idProyek">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih proyek terlebih dahulu pada tab PR Header.
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <div class="flex flex-wrap justify-between items-center mb-1">
          <div class="mb-4 md:mb-0 mr-4">
            <div class="flex">
              <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2" :disabled="!idProyek">Add</vs-button>
              <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="items.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vs-table :data="items" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Action</vs-th>
        <vs-th class="whitespace-no-wrap">Nama Item</vs-th>
        <vs-th class="whitespace-no-wrap">Satuan</vs-th>
        <vs-th class="whitespace-no-wrap">Harga</vs-th>
        <vs-th class="whitespace-no-wrap">Qty</vs-th>
        <vs-th class="whitespace-no-wrap">Total</vs-th>
        <vs-th class="whitespace-no-wrap">Pilihan Vendor</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
          <vs-td class="whitespace-no-wrap">
            <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div>
              <vx-input-group>
                <vs-input :value="item.nama_item_pengadaan" @click="chooseItemPengadaan(item.uuid)" disabled/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="chooseItemPengadaan(item.uuid)"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <vs-input class="w-full" v-model="item.satuan_item_pengadaan" disabled/>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <v-money class="w-full" v-model="item.harga" type="number"/>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <vs-input class="w-full" v-model="item.qty" type="number"/>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <v-money class="w-full" :value="(item.harga || 0) * (item.qty || 0)" disabled/>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <vs-button :type="item.vendors.length > 0 ? 'filled' : 'border'"
                       :disabled="!item.id_item_pengadaan"
                       @click="chooseRekanan(item.uuid)">
              {{ item.vendors.length }} pilihan
            </vs-button>
          </vs-td>
        </vs-tr>
        <!--footer-->
        <vs-tr v-if="data.length > 0">
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap">TOTAL NILAI PR</vs-td>
          <vs-td class="whitespace-no-wrap"><v-money class="w-full" :value="grandTotal" disabled/></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Item Pengadaan"
              :active.sync="modalItemPengadaan.active">
      <ItemPengadaan :selectable="true"
                     :externalFilter="filterItemPengadaan"
                    @selected="onSelectedModalItemPengadaan"/>
    </vs-popup>

    <RekananChooser :active.sync="modalRekananChooser.active"
                    :rowUuid="modalRekananChooser.rowUuid"
                    @selected="onSelectedModalRekananChooser"/>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import VMoney from '@/views/components/v-money/VMoney'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'TabItem',
  props: {
    initData: { default: null, type: Array } // for edit
  },
  components: {
    ItemPengadaan: () => import('@/views/pages/master/item-pengadaan/ItemPengadaan'),
    RekananChooser: () => import('@/views/pages/procurement/pengadaan/parts/RekananChooser'),
    ValidationErrors,
    VMoney
  },
  computed: {
    errors () {
      return this.$store.state.procurement.pengadaanAdd.errors.tabItem.messages
    },
    tabHeader () {
      return this.$store.state.procurement.pengadaanAdd.tabHeader
    },
    idProyek () {
      return this.tabHeader.id_proyek
    },
    filterItemPengadaan () {
      return this.tabHeader.id_proyek ? { id_proyek: this.tabHeader.id_proyek } : null
    },
    grandTotal () {
      return _.sumBy(this.items, item => (item.harga || 0) * (item.qty || 0))
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.items = _.cloneDeep(newVal)
      }
    },
    items: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    },
    idProyek (newVal, oldVal) {
      if (newVal !== oldVal) this.items = []
    }
  },
  data () {
    return {
      modalItemPengadaan: {
        rowUuid: null,
        active: false
      },
      modalRekananChooser: {
        rowUuid: null,
        active: false
      },
      items: []
    }
  },
  methods: {
    chooseItemPengadaan (rowUuid) {
      this.modalItemPengadaan.rowUuid = rowUuid
      this.modalItemPengadaan.active = true
    },

    chooseRekanan (rowUuid) {
      this.modalRekananChooser.rowUuid = rowUuid
      this.modalRekananChooser.active = true
    },

    onSelectedModalItemPengadaan (data) {
      // validate for same item
      if (_.find(this.items, item => item.id_item_pengadaan === data.id) !== undefined) {
        return this.notifyWarning('Item yang sama tidak dapat ditambahkan lagi.')
      }
      const uuid = this.modalItemPengadaan.rowUuid
      const index = _.findIndex(this.items, item => item.uuid === uuid)
      this.items[index].id_item_pengadaan = data.id
      this.items[index].nama_item_pengadaan = data.nama
      this.items[index].satuan_item_pengadaan = data.satuan
      this.modalItemPengadaan.active = false
    },

    onSelectedModalRekananChooser (vendors) {
      const uuid = this.modalRekananChooser.rowUuid
      const index = _.findIndex(this.items, item => item.uuid === uuid)
      this.items[index].vendors = vendors
    },

    addRow () {
      const row = { uuid: uuid(), vendors: [] }
      this.items.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.items, item => item.uuid === uuid)
      this.items.splice(index, 1)
    },

    clearRows () {
      this.items = []
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('procurement/pengadaanAdd/SET_TAB_ITEM', payload)
    }, 500),

    prev () {
      this.$store.commit('procurement/pengadaanAdd/SET_ACTIVE_TAB', 0)
    }
  }
}
</script>
