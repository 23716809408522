<template lang="html">
  <div :class="[{
        'input-icon-validate-success': success,
        'input-icon-validate-danger': danger,
        'input-icon-validate-warning': warning
      }]">

    <input
      type="tel"
      :value="formattedValue"
      @input="onInput"
      v-bind="$attrs"
      v-money="{precision, decimal, thousands, prefix, suffix}"
      :class="classObject"/>
    <div
      v-if="success"
      class="con-text-validation span-text-validation-success vs-input--text-validation-span">
      <span class="span-text-validation">{{ successText }}</span>
    </div>
    <div
      v-else-if="danger"
      class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
      <span class="span-text-validation">{{ dangerText }}</span>
    </div>
    <div
      v-else-if="warning"
      class="con-text-validation span-text-validation-warning vs-input--text-validation-span">
      <span class="span-text-validation">{{ warningText }}</span>
    </div>
    <div
      v-if="descriptionText"
      class="con-text-validation span-text-validation vs-input--text-validation-span">
      <span class="span-text-validation">{{ descriptionText }}</span>
    </div>
  </div>
</template>

<script>
import money from './directive'
import defaults from './options'
import { format, unformat } from './utils'

export default {
  name: 'VMoney',
  inheritAttrs: false,
  directives: { money },
  props: {
    value: {
      required: true,
      default: ''
    },
    masked: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: () => defaults.precision
    },
    decimal: {
      type: String,
      default: () => defaults.decimal
    },
    thousands: {
      type: String,
      default: () => defaults.thousands
    },
    prefix: {
      type: String,
      default: () => defaults.prefix
    },
    suffix: {
      type: String,
      default: () => defaults.suffix
    },
    success: {
      default: false,
      type: Boolean
    },
    danger: {
      default: false,
      type: Boolean
    },
    warning: {
      default: false,
      type: Boolean
    },
    successText: {
      default: null,
      type: String
    },
    dangerText: {
      default: null,
      type: String
    },
    warningText: {
      default: null,
      type: String
    },
    descriptionText: {
      default: null,
      type: String
    },
    theme: {
      required: false,
      type: String,
      default: 'theme1',
      validator: (value) => {
        return ['theme1', 'theme2', 'theme3', 'theme4'].includes(value)
      }
    }
  },
  data () {
    return {
      currentValue: '',
      formattedValue: ''
    }
  },
  computed: {
    classObject () {
      return {
        'v-money vs-inputx vs-input--input normal': this.theme === 'theme1',
        'w-full border border-solid d-theme-border-grey-light py-3 px-2 text-xs text-right': this.theme === 'theme2',
        'w-full border-none py-3 px-2 text-xs text-right bg-transparent': this.theme === 'theme3',
        'v-money vs-inputx vs-input--input normal text-right': this.theme === 'theme4'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue === 0) {
          this.formattedValue = '0'
        } else {
          const formattedValue = !newValue ? '' : format(newValue, this.$props)
          if (formattedValue !== this.formattedValue) {
            this.formattedValue = formattedValue
          }
        }
      }
    }
  },
  methods: {
    onInput (evt) {
      const newValue = evt.target.value
      if (newValue !== this.currentValue) {
        this.currentValue = newValue
        this.$emit('input', this.masked ? newValue : (!newValue ? '' : unformat(newValue, this.precision)))
      }
    }
  }
}
</script>
